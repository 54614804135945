import { useEffect } from 'react'
import { getSacoToken } from 'apiConfig/apiMethods'
import { pushPageViewPreventDuplicates } from '../utils/gtmUtils';

/*
    Fetch a token to append to the Saco URL so that visitors can be tracked
    On fail, we redirect to DIK homepage
*/

const SacoLonesokRedirect = () => {
  useEffect(() => {
    pushPageViewPreventDuplicates();
    const fetchData = async () => {
      try {
        const data = await getSacoToken();
        if (data && data.data && data.data.token) {
          const token = data.data.token
          window.location.href = `https://lonesok.saco.se/token/${token}`
        } else {
          window.location.href = `/`
        }
      } catch (error) {
        window.location.href = `/`
      }
    }

    fetchData()
  }, [])
  return null
}

export default SacoLonesokRedirect
